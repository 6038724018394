<template>
    <div class="terms-and-conditions" v-if="!acceptedTerms">
      <div class="modal" :class="{ 'fade-out': fadeOut}">
        <h2 class="modal-header">Terms and Conditions</h2>
        <div class="content">
            <h3>Introduction</h3>
            <p>OnlyMemes, accessible via https://onlymemes.love, is a digital platform operated by OnlyMemes, Inc. ("OnlyMemes," "we," "us," or "our"). This Interface facilitates transactions on the Solana blockchain, allowing users to purchase meme-themed cryptocurrencies.</p>

            <h3>Acceptance of Terms</h3>
            <p>By accessing and using OnlyMemes, you agree to be bound by these Terms of Use, our Privacy Policy, and any other legal notices published by us on the Interface. If you are using OnlyMemes on behalf of an entity, you represent that you have the authority to bind that entity to these Terms.</p>

            <h3>Definitions</h3>
            <p>"Service" refers to the functionalities provided by OnlyMemes as described here.<br>
            "User," "you," and "your" refer to the individual or entity using the Service.</p>

            <h3>Scope of OnlyMemes Services</h3>
            <p>OnlyMemes provides an interactive interface for users to engage in the purchase of digital currencies. We charge a transaction fee of 0.69% on each purchase made through our platform.<br>
            We do not serve as a financial advisor or custody agent of any kind.<br>
            The Service includes real-time information regarding cryptocurrencies, their availability on the Solana blockchain, and facilitates transactions with third-party services like Jupiter Swap.</p>

            <h3>Audits of Meme Coins</h3>
            <p>OnlyMemes performs preliminary audits of meme coins listed on our platform in an effort to verify their legitimacy and to protect our users from potential fraud. As part of our commitment to creating a safe and inclusive environment, we also strive to eliminate any content involving hate speech or other NSFW material. However, please note that these audits are based on information available at the time and do not guarantee the absence of such content or the future success of the coins. Decisions to purchase or trade any cryptocurrencies through our Service are made solely at the user's discretion and risk.</p>

            <h3>User Account and Security</h3>
            <p>Registration: Users may be required to register and create an account to use certain features. You agree to provide accurate and complete information and keep this information updated.<br>
            Security: You are responsible for maintaining the security of your account credentials. OnlyMemes will not be liable for any loss or damage arising from unauthorized use of your account.</p>

            <h3>Compliance with Laws</h3>
            <p>You are responsible for complying with all applicable laws, regulations, and sanctions programs relating to your use of OnlyMemes.<br>
            Prohibited Jurisdictions: Users from certain jurisdictions may not use OnlyMemes. It is your responsibility to ensure that you are not accessing the Service from a prohibited jurisdiction as detailed in our compliance policy.</p>

            <h3>Third-Party Links and Services</h3>
            <p>OnlyMemes may contain links to websites and services provided by third parties. These links are provided for your convenience only. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You acknowledge and agree that OnlyMemes shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such websites or services.</p>

            <h3>Intellectual Property</h3>
            <p>The Interface and all intellectual property pertaining to it, including trademarks, logos, graphics, and software, are owned by OnlyMemes or our licensors and are protected under copyright and intellectual property laws.</p>

            <h3>User Conduct</h3>
            <p>You agree not to use OnlyMemes to conduct any unlawful activity, engage in any transaction involving illegal goods, or use OnlyMemes in any way that could damage, disable, overburden, or impair the Service.<br>
            You are prohibited from using any automated system or software to extract data from the Site for commercial purposes ("screen scraping"), except where permitted under these Terms or through a license granted by us.</p>

            <h3>Financial Risks</h3>
            <p>Cryptocurrency investments carry inherent risks due to volatility and regulatory uncertainty. By using OnlyMemes, you acknowledge and agree that you are fully aware of and accept the risks associated with digital and cryptocurrency transactions.</p>

            <h3>No Warranties</h3>
            <p>The Service is provided "as is" and without any warranties, express or implied. OnlyMemes expressly disclaims any warranties of merchantability, fitness for a particular purpose, or non-infringement.</p>

            <h3>Limitation of Liability</h3>
            <p>OnlyMemes will not be liable for any direct, indirect, incidental, special, consequential, or punitive damages resulting from the use or inability to use the Service.</p>

            <h3>Indemnification</h3>
            <p>You agree to indemnify, defend, and hold harmless OnlyMemes and our officers, directors, employees, agents, affiliates, successors, and assigns from any and all claims, liabilities, damages, costs, and expenses, including reasonable attorneys' fees, arising from your use of the Service, your violation of these Terms, or your violation of any rights of another.</p>

            <h3>Dispute Resolution</h3>
            <p>Arbitration: Any dispute, controversy, or claim arising out of or related to these Terms or the Service shall be settled by binding arbitration in accordance with the rules of a recognized arbitration association as agreed upon by the parties.<br>
            Class Action Waiver: You waive any right to participate in class actions against OnlyMemes.</p>

            <h3>Severability</h3>
            <p>If any provision of these Terms is held to be invalid, illegal, or unenforceable for any reason, such provision shall be modified to reflect the parties' intention or eliminated to the minimum extent such that the remaining provisions of the Terms will continue in full force and effect.</p>

            <h3>Force Majeure</h3>
            <p>OnlyMemes shall not be liable for any delay or failure to perform resulting from causes outside its reasonable control, including but not limited to acts of God, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, strikes, or shortages of transportation facilities, fuel, energy, labor, or materials.</p>

            <h3>Changes and Amendments</h3>
            <p>We reserve the right to amend these Terms at any time. Your continued use of OnlyMemes following the posting of revised Terms means that you accept and agree to the changes.</p>

            <h3>Governing Law</h3>
            <p>These Terms shall be governed by and construed in accordance with the laws of the State of California, excluding its conflict of law principles.</p>

            <h3>Contact Information</h3>
            <p>If you have any questions about these Terms, please contact us at support@onlymemes.love.</p>
        </div>

        <div class="footer">
            <input type="checkbox" id="acceptTerms" v-model="acceptedTerms">
            <label for="acceptTerms">I certify that I have read and accept the Terms and Conditions and am at least 18 years of age.</label>
        </div>
      </div>
    </div>
  </template>
  
  <script>
    export default {
    name: 'TermsAndConditions',
    data() {
        return {
        acceptedTerms: false, // This will control the display of the modal
        fadeOut: false
        };
    },
    methods: {
        acceptTerms() {
            this.fadeOut = true;
            setTimeout(() => {
                this.acceptedTerms = false;
            }, 500);
        }
    }
    }
  </script>
  
  <style scoped>
.terms-and-conditions {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 7;
}

.modal {
  width: 25%;
  max-height: 65%;
  background-color: rgba(0, 0, 0, 0.637);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Allows for scrolling */
  transition: opacity 3s ease-in-out;;
  min-width: 360px;
}
.fade-out {
    transition: 3s ease-out;
    opacity: 0;
}

.modal-header {
  color: #fff;
}

.content {
  color: #fff;
  overflow-y: auto;
  padding-bottom: 100px;
}

.footer {
  padding: 10px 20px;
  background-color: #517051;
  color: white;
  text-align: center;
  position: fixed;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
  width: 95%;
  z-index: 7;
}
input[type="checkbox"] {
  margin-right: 5px;
}
</style>
  