<template>
  <header>
    <div class="navbar">
      <div class="left-container">
        <button @click="toggleMenu" class="hamburger-button">
          &#9776; <!-- Hamburger Icon -->
        </button>
        <div class="logo homepage">OnlyMemes</div>
      </div>
      <div class="wallet-adapater-holder">
        <wallet-multi-button></wallet-multi-button>
      </div>
      <transition name="slide" mode="out-in" @before-enter="beforeEnter" @enter="enter" @leave="leave">
        <div v-show="menuVisible" class="menu-items">
          <div class="how-to-play">
            <h2>How To Play:</h2>
            <div class="heart-value-input">
              <span>Enter ❤️ Value = </span>
              <div class="input-field-color">
                <input type="text" id="heartValue" placeholder="e.g. 0.005 SOL" v-model="amountToBuy" @change="validateInput">
              </div>
            </div>
            <div class="instructions">
              <p><span>❤️</span> = Spend 1 Heart</p>
              <p><span>💕</span> = Spend 2 Hearts</p>
              <p><span>✖️</span> = Pass</p>
            </div>
          </div>
          <div class="transaction-status-success">
            <input type="text" placeholder="+15,773,125 SKIBIDI" v-model="transactionStatusSuccessful" @change="validateInput" >
          </div>
          <div class="transaction-status-failed">
            <span class="failed-icon">
              <span class="x-emoji">✖️</span>
            </span>
            <input type="text" placeholder="Transaction Failed" v-model="transactionStatusFailed" @change="validateInput" >
          </div>
          <div class="transaction-status-pending">
            <span class="loading-icon">
              <span class="om-text">OM</span>
            </span>
            <input type="text" placeholder="Transaction Pending" v-model="transactionStatusPending" @change="validateInput" >
          </div>
          <transition name="logos-fade-in">
            <div v-if="showLogos" class="link-position">
              <a href="#about" class="menu-item">Powered By:</a>
              <div class="menu-item logos">
                <a href="https://www.helius.dev" target="_blank" rel="noreferrer">
                  <img src="https://d2y4xlojzk24f7.cloudfront.net/media/helius_logo.svg" alt="Helius Logo" />
                </a>
                <a href="https://www.jup.ag" target="_blank" rel="noreferrer">
                  <img src="https://d2y4xlojzk24f7.cloudfront.net/media/jupiter_logo.svg" alt="Jupiter Logo" />
                </a>
                <a href="https://www.solana.com" target="_blank" rel="noreferrer">
                  <img src="https://d2y4xlojzk24f7.cloudfront.net/media/solana_logo.svg" alt="Solana Logo" />
                </a>
              </div>
            </div>
          </transition>
          <div class="toggle">
            <div class="label-switch-container">
              <div class="label">OnlyMemes</div>
              <label class="switch">
                <input type="checkbox" v-model="onlyMemesMode" id="onlyMemesModeToggle" @change="emitOnlyMemesMode">
                <span class="slider round"></span>
              </label>
            </div>
            <div class="label-switch-container-new">
              <div class="label">Newest Tokens</div>
              <label class="switch">
                <input type="checkbox" v-model="recentlyCreatedMode" id="recentlyCreatedToggle" @change="emitRecentlyCreatedMode">
                <span class="slider round"></span>
              </label>
            </div>
            <div class="label-switch-container">
              <div class="label">Mute</div>
              <label class="switch">
                <input type="checkbox" v-model="muteEnabled" id="soundToggle" @change="emitSoundToggle">
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </header>
</template>


<script>
  import { WalletMultiButton } from 'solana-wallets-vue'

  export default {
    name: 'HeaderMeme',
    components: {
      WalletMultiButton
    },
    data() {
      return {
        menuVisible: false, // State to track visibility of the hamburger menu
        onlyMemesMode: false,
        recentlyCreatedMode: false,
        muteEnabled: false,
        showLogos: false,
        // amountToBuy: 0.001
      };
    },
    mounted() {
      // Set menuVisible based on window width
      this.menuVisible = window.innerWidth >= 1024;
      if (this.menuVisible) { this.showLogos = true }
      this.applyMenuPosition();
    },
    methods: {
      // Toggles the visibility of the menu
      toggleMenu() {
        this.menuVisible = !this.menuVisible;
        this.applyMenuPosition();
        if (this.menuVisible) {
          // Delay showing the logos for a smoother transition after the menu opens
          setTimeout(() => {
            this.showLogos = true;
          }, 400); // Adjust delay if necessary
        } else {
          // Hide logos immediately when the menu is closed
          this.showLogos = false;
        }
      },
      openMenu() {
        this.menuVisible = true;
        this.applyMenuPosition();
      },
      applyMenuPosition() {
        const menu = this.$el.querySelector('.menu-items');
        if (this.menuVisible) {
          menu.style.left = "0"; // End position on-screen
        } else {
          menu.style.left = "-250px"; // Start position off-screen
        }
      },
      // Prepares the element for the 'enter' transition
      beforeEnter(el) {
        el.style.left = "-250px"; // Start position off-screen
      },
      // Handles the 'enter' transition
      enter(el, done) {
        el.getBoundingClientRect(); // Forces reflow
        el.style.transition = 'left 1s ease'; // Smooth transition for the left property
        el.style.left = "0"; // End position on-screen
        done();
      },
      // Handles the 'leave' transition
      leave(el, done) {
        el.style.left = "-250px"; // Moves the element back off-screen
        done();
      },
      validateInput(event) {
        const value = event.target.value;
        event.target.value = value.replace(/[^0-9.]/g, '');
        this.$emit('amountToBuy', parseFloat(this.amountToBuy))
      },
      emitOnlyMemesMode() {
        this.$emit('onlyMemesModeChange', this.onlyMemesMode); // Migrated method
      },
      emitRecentlyCreatedMode() {
        this.$emit('recentlyCreatedModeChange', this.recentlyCreatedMode);
      },
      emitSoundToggle() {
        this.$emit('soundToggleChange', this.muteEnabled);
      },
    }
  }
</script>


<style>
/* Header styles */
header {
    background-color: #FF6B6B;
    padding: 0px 0px 0px 0px;
    height: 100px;
    margin-bottom: 1vh;
    position: static;
    width: 100%;
}

/* Overrides bootstrap cutting off bottom of header */
header, header::before, header::after {
  box-sizing: content-box;
}

.navbar {
  z-index: 5;
}

.left-container {
    display: flex;
    align-items: center;
}

.hamburger-button {
  background: none;
  border: none;
  color: white;
  font-size: 28px;
  padding: 20px;
  cursor: pointer;
  z-index: 6; /* Ensures the button is above the sliding menu */
}

.menu-items {
  display: flex;
  flex-direction: column;
  background-color: #333;
  width: 350px; /* Adjust width of the sidebar menu */
  position: fixed;
  top: 0;
  left: -250px; /* Start off-screen */
  transition: left 0.3s ease;
  height: 100%;
  align-items: center;
}

.heart-value-input {
  font-size: x-large;
  font-weight: 500;
  border-radius: 10px;
  padding-bottom: 10px;
  padding-top: 5px;
  background-color: #FF6B6B;
  width: 100%;
}

.heart-value-input input::placeholder {
  color: #bbb;
  font-family: "Open Sans";
}

.heart-value-input input:focus {
  outline: none;
  border-color: #20c997;
}

/* Chrome, Edge, Safari Remove Spin Buttons */
.heart-value-input input::-webkit-inner-spin-button,
.heart-value-input input::-webkit-outer-spin-button {
    -webkit-appearance: none; /* Removes the default appearance of the spin button in WebKit browsers */
    margin: 0; /* Removes any margin that might be associated */
}

/* General styling to ensure no other browser shows a spinner */
.heart-value-input input[type=number]::-webkit-clear-button {
    display: none; /* Removes clear buttons in input fields in IE and WebKit browsers */
}
.menu-item {
  color: white;
  padding: 10px;
  text-decoration: none;
  display: block;
}

.slide-enter-active, .slide-leave-active {
  transition: left 0.3s ease;
}

.slide-enter-from, .slide-leave-to {
  left: -250px; /* Start and end off-screen */
}

.slide-enter-to, .slide-leave-from {
  left: 0; /* End and start on-screen */
}

.logos-fade-in-enter-active, .logos-fade-in-leave-active {
  transition: opacity 1s ease; /* Test with only fade */
}

.logos-fade-in-enter-from, .logos-fade-in-leave-to {
  opacity: 0;
}

.logos-fade-in-enter-to, .logos-fade-in-leave-from {
  opacity: 1;
}

/* Logo styles */
.logo {
  color: #343a40; 
  border: 4px solid #343a40;
  padding: 5px 10px;
  border-radius: 30px;
  font-size: clamp(12px, 2.7vh, 24px);
}

.logo-button {
  color: #343a40; 
  border: 4px solid #343a40;
  padding: 5.5px 11px;
  border-radius: 34px;
  font-size: 36px;
  margin: 0 auto; /* Center the logo-button */
  text-decoration: none; /* Remove underline */
  display: relative; 
  width: fit-content; 
}

/* Navigation styles */
nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

nav ul li {
  margin-right: 20px;
}

/* Link styles */
nav ul li a {
  color: #343a40; /* Dark text color for links */
  text-decoration: none;
  transition: color 0.3s ease; /* Smooth color transition on hover */
}

nav ul li a:hover {
  color: #495057; /* Slightly darker text color on hover */
}

.main-nav {
  margin-left: auto;
  align-items: center;
}

.wallet-adapater-holder {
  margin-right: 2vw;
}

/* Styles for "Connect Wallet" link */
.connect-wallet-link {
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #20c997; /* Refreshing green color for button */
  color: white; /* White text color */
  /* text-decoration: none; */
  transition: background-color 0.3s ease; /* Smooth color transition on hover */
  display: block;
  margin-left: auto;
}

.connect-wallet-link:hover {
  background-color: #84efff; 
}

/* How to Play section styles */
.how-to-play {
  color: white;  /* Ensures text is visible against a dark background */
  padding: 10px; /* Add some padding around the text */
  font-size: x-large;
  margin-top: 45%;
  padding-bottom: 2vh;
  z-index: 5;
  position: relative;
  width: 100%;
}

.instructions {
  top: min(10px);
  position: relative;
}

.toggle {
  position: absolute;
  top: 20px;
  display: flex;
  flex-direction: column;
  left: 65%;
  padding-right: 5%;
  padding-left: 0;
  transform: translateX(-50%);
  font-size: clamp(20px, 1.8vh, 22px);
}

.label-switch-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Adjust space between lines as needed */
}

.label-switch-container-new {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Adjust space between lines as needed */
}

.label {
  position: relative;
  color: #cad3e6; /* Matching the color scheme of other text */
  /* font-size: 1.8vh; */
  font-weight: 800;
  margin-left: auto;
  padding-right: 5px;
  display: flex;
  font-size: clamp(22px, 1.8vh, 22px);
  white-space: nowrap;
}

.link-position {
  display: block;
  position: absolute;
  left: 50%;
  bottom: 5vh; /* Adjust this as needed for optimal visibility */
  text-align: center;
  z-index: 6;
  transform: translateX(-50%);
}

.transaction-status-success,
.transaction-status-failed,
.transaction-status-pending {
  display: flex; /* Align icon and text field horizontally */
  align-items: center;
  font-size: small;
  font-weight: 1000;
  font: bold;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px; /* Adjust space between the status blocks */
  text-align: center;
  width: 80%;
  box-sizing: border-box; /* Ensure padding doesn’t affect width */
  background-color: #7c7d71; /* Background color for the status fields */
  height: 40px; /* Adjust height to match SKIBIDI field */
}

.transaction-status-success input,
.transaction-status-failed input,
.transaction-status-pending input {
  width: 100%; /* Make the input field fill the container */
  border: none;
  background: transparent;
  outline: none;
  color: inherit;
  font-family: "Open Sans";
  text-align: center;
  align-items: center;
  box-sizing: border-box;
}

.transaction-status-success {
  color: rgb(136, 225, 35);
}
.transaction-status-failed {
  color: rgb(255, 54, 54);
  font-weight: 500;
}
.transaction-status-pending {
  color: #FF6B6B;
}

/* Placeholder styling */
.transaction-status-success input::placeholder,
.transaction-status-failed input::placeholder,
.transaction-status-pending input::placeholder {
  color: currentColor; /* Matches placeholder color to status color */
}

/* Loading Icon Circle */
.loading-icon {
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  color: #FF6B6B; /* Match text color */
  border: 2px solid #FF6B6B; /* Circle border color */
  border-radius: 50%; /* Creates circular border */
  width: 24px;
  height: 24px;
  font-size: 10px; /* Adjust font size within circle */
}

/* Spinning OM Text */
.om-text {
  display: inline-block;
  animation: spin 3s linear infinite; /* Only spin the text */
  font-weight: bolder;
}

.failed-icon {
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: large;
  color: red;
  padding-left: 2px;
}

.logos {
  display: flex;
  justify-content: center; /* Center-aligns logos */
  gap: 20px; /* Space between each logo */
  margin-top: 5px; /* Space between "Powered By:" and logos */
}

.logos a {
  display: inline-block;
}

.logos a:first-child img {
  height: 46px; /* Slightly larger than the default 30px */
  width: auto;
  position: relative;
  bottom: 2px;
}

.logos img {
  height: 36px; /* Uniform logo height */
  width: auto; /* Maintain aspect ratio */
  max-width: 100px; /* Cap maximum width for balance */
  object-fit: contain; /* Ensure logos fit within specified dimensions */
  transition: transform 0.2s ease; /* Adds a slight hover effect */
}

.logos img:hover {
  transform: scale(1.1); /* Slightly enlarge the logo on hover */
}

/* Toggle switch styling */
.switch {
  position: relative;
  width: clamp(50px, 4vh, 56px);
  height: clamp(32px, 2.5vh, 56px);
}

/* Hide default HTML checkbox */
.switch input { 
  opacity: 0;
  position: absolute;
  width: 0;
  min-width: 0;
  max-width: 0;
  height: 0;
  z-index: 2;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #8d8d8d;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 72%;
  width: 50%;
  left: 5px;
  bottom: 15%;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  transform: translateX(55%);
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media (max-width: 768px) {
  .banner {
      flex-direction: column;
      height: 10vh; /* Adjust height for stacked layout */
  }
}
@media (max-width: 768px) {
  body, html {
      width: 100%;
      overflow-x: hidden; /* Prevent horizontal scrolling */
      overflow-y: hidden;
  }
}
@media (min-height: 1024px) {
  header {
      margin-bottom: 40px;
  }
}
@media (width: 912px) {
  .main-nav, .header {
    padding-right: 5vw;
    overflow-x: hidden;
  }
}
@media (width: 768) {
  .connect-wallet-link {
    padding-left: 20vw;
  }
}
</style>
